<template>
  <div
    :id="`wrapper_${index}`"
    class="grid grid-cols-1 gap-4 overflow-hidden p-10 lg:py-20"
    :class="
      (slice.primary.cards.length == 1)
        ? 'md:grid-cols-1'
        : (slice.primary.cards.length % 3 === 0)
          ? 'md:grid-cols-3'
          : 'md:grid-cols-2'
    "
  >
    <div
      v-for="(card, index) in slice.primary.cards"
      :key="index"
      class="card group cursor-pointer"
    >
      <NuxtLink :to="prismic.asLink(card.link) ?? ''">
        <h2
          class="text-header1 leading-loose"
          :class="card.theme === 'Blue' ? 'text-blue-75' : card.theme === 'Rust' ? 'text-rust-75' : card.theme === 'Green' ? 'text-green-75' : 'text-charcoal'"
        >
          {{ card.title_first }}
        </h2>
        <PrismicImage
          :field="card.image"
          class="max-h-[500px] w-full object-cover"
        />
        <h2
          class="inline-block text-header1 leading-loose"
          :class="card.theme === 'Blue' ? 'text-blue-75' : card.theme === 'Rust' ? 'text-rust-75' : card.theme === 'Green' ? 'text-green-75' : 'text-charcoal'"
        >
          {{ card.title_second }}
        </h2>
        <ArrowRight
          class="ml-[20px] mt-[-20px] inline-block w-14 transition-all group-hover:ml-[40px]"
          :class="card.theme === 'Blue' ? '*:!fill-blue-75' : card.theme === 'Rust' ? '*:!fill-rust-75' : card.theme === 'Green' ? '*:!fill-green-75' : '*:!fill-charcoal'"
        />
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Content } from "@prismicio/client";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import ArrowRight from "~/assets/svgs/arrow_right.svg";

gsap.registerPlugin(ScrollTrigger);

const prismic = usePrismic();

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
  getSliceComponentProps<Content.CardsSlice>([
    "slice",
    "index",
    "slices",
    "context",
  ]),
);

onMounted(() => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: `#wrapper_${props.index.toString()}`,
      start: "top 80%",
      end: "bottom bottom",
      scrub: true,
    },
  });

  tl.from([`#wrapper_${props.index.toString()} .card`], { y: "200", opacity: 0, duration: 0.5, stagger: 0.2, ease: "power4.out" });
});
</script>

<style>
.container .pusher {
  padding:0;
}
</style>
